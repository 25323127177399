export const ListRentalHistoryAdmin = `query MyQuery($NumberItem: Int, $Page: Int, $FilterUserID: Int) {
    getRentalHistoryListAdmin(input: {NumberItem: $NumberItem, Page: $Page, FilterUserID: $FilterUserID}) {
        CountAll
        NumberItem
        Page
        Items {
            RentID
            PackageName
            VideoTitle
            VideoID
            Stills {
                bucket
                region
                key
                order
            }
            StarUserID
            StarUserName
            RentType
            CreatedAt
        }
    }
}`

export const ListSubHistoryAdmin = `query MyQuery($FilterEmail: String,$NumberItem: Int,$Page: Int) {
    listSubHistoryAdmin(input: {FilterEmail: $FilterEmail, NumberItem: $NumberItem, Page: $Page}) {
        CountAll
        NumberItem
        Page
        FilterEmail
        Items {
            OrderID
            OrderCode
            Amount
            CreatedAt
            Status
            PaymentType
            Type
            Package
            Name
            UserID
            Email
            SubscriptionID
            UserSubscriptionID
        }
    }
}`

export const CancelSubscription = `mutation MyMutation($UserSubscriptionID: Int!) {
    cancelSubscriptionAdmin(UserSubscriptionID: $UserSubscriptionID) {
        Message
        Status
    }
}`