import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, Button, Paper, Tabs, Tab } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { ListRentalHistoryAdmin } from '../../lib/graphql-command/command';
import { getImage } from '../../lib/utils';
import moment from 'moment';
import { Link, navigate } from 'gatsby';
import { Pagination } from '@material-ui/lab';
import noimage from '../../images/noimage.png';

const useStyle = makeStyles(theme => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
	videoTitle: {
		width: '40%'
	},
	image: {
		width: '60px',
		height: '60px',
		objectFit: 'cover'
    },
    link: {
        textDecoration: 'none'
    }
}))

const RentalHistories = ({ UserID }) => {
    const classes = useStyle();
    const [rentals, setRentals] = useState();
    const [page, setPage] = useState(1)
    const pageSize = 20

    useEffect(() => {
        getRentalHistories();
    }, [page])

    const getRentalHistories = () => {
        API.graphql(graphqlOperation(ListRentalHistoryAdmin, {
            NumberItem: pageSize,
            Page: page,
            FilterUserID: UserID
        }))
        .then(result => {
            setRentals(result.data?.getRentalHistoryListAdmin);
            console.log('rentals', rentals);
        })
        .catch(err => console.log('rentals-err', err))
    }

    const displayDate = (date) => {
        return moment(date).format('Do MMMM, YYYY');
    }

    const displayExpireDate = (date) => {
        return moment(date).add(21, 'days').format('Do MMMM, YYYY');
    }

    const displayPackageID = (rental) => {
        if (rental.RentType == 1) {
            if (parseInt(rental.PackageName) == 1) {
                return 'Quickie';
            } else if (parseInt(rental.PackageName) == 2) {
                return 'Monthly';
            } else if (parseInt(rental.PackageName) == 3) {
                return 'Binge';
            }
        }

        return rental.PackageName;
    }

    const pageCount = () => {
        return Math.ceil((rentals?.CountAll||0)/pageSize)
    }
    const rentalTypeText = (type) => {
        if (type == 1) {
            return 'subscription';
        } else if (type == 2) {
            return 'credit';
        } else if (type == 3) {
            return 'lacarte';
        }
    }
	console.log(rentals)

    return (
        <Box>
            {rentals?.Items?.length > 0 && <TableContainer className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Package Id</TableCell>
                            <TableCell>Video Title</TableCell>
                            <TableCell>Video Thumb</TableCell>
                            <TableCell>MLNP Star name</TableCell>
                            <TableCell>Rental type</TableCell>
                            <TableCell>Rental date</TableCell>
                            <TableCell>Expire date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rentals?.Items?.map(rental => (
                            <TableRow className={classes.row}>
                                <TableCell>{displayPackageID(rental)}</TableCell>
                                <TableCell>
                                    <Link to={`${process.env.GATSBY_USER_SITE}video/${rental.VideoID}`} className={classes.link}>{rental.VideoTitle}</Link>
                                </TableCell>
                                <TableCell><img src={rental?.Stills[0]?.key ? getImage(rental?.Stills[0]) : noimage} className={classes.image}/></TableCell>
                                <TableCell>
                                    <Link to={`${process.env.GATSBY_USER_SITE}mlnpstar/${rental.StarUserName}`} className={classes.link}>{rental.StarUserName}</Link>
                                </TableCell>
                                <TableCell>{rentalTypeText(rental.RentType)}</TableCell>
                                <TableCell>{displayDate(rental.CreatedAt)}</TableCell>
                                <TableCell>{displayExpireDate(rental.CreatedAt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
			{rentals?.Items?.length <= 0 && <Box>
				No Rental
			</Box>}
            {rentals && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {rentals?.CountAll} items
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                            <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}

            <Link to={`/users/${UserID}`}>Back to Edit User</Link>
      </Box>
    )
}

export default RentalHistories;